<template>
  <div class="photo-upload-container">
    <custom-dialog :isDialogOpen="isDialogOpen" @close="closeDialog" @save="saveOrUpdateTempData"/>
    <NoPhotos
      v-if="this.$route.path === '/photo'"
      :initImgUploadCamera="initImgUploadCamera"
      :updateUrl=updateUrl
      :checkReadonly="checkReadonly"
    />
    <div class="photo-upload-content mt-3" v-if="this.$route.path === '/photo/upload'">
      <div class="mb-3">
        <div class="photo-upload rounded-2 mb-4">
          <div :class="isMobile ? 'px-2 pb-5' : 'px-5 pb-5'">
            <ListedCarMessage />
            <div class="upload-section">
              <h1
                class="font-weight-bold text-left fs-20 content-title mb-0"
                :class="{
                  'centered-title':
                    !(showImgSizeErr || showImgTypeErr || showMaxLengthErr) &&
                    !isUseGuidedUpload &&
                    (uploadImage.length === 0 || (uploadImage.length >= 10 && uploadImage.length < 30)),
                    'guided-upload-no-err': isUseGuidedUpload && !(showImgSizeErr || showImgTypeErr || showMaxLengthErr),
                }"
                :style="isMobile ? '' : `padding-left: ${isUseGuidedUpload ? '1.75rem' : '2.25rem'};`"
              >
                ADD CAR PHOTOS
              </h1>
              <div class="upload-switcher" :class="{'guided-upload-mode': isUseGuidedUpload}">
                <div
                  class="option"
                  :class="{ active: !isUseGuidedUpload, disabled: isUploadedImages, 'show-tooltip': isUploadedImages && !checkReadonly }"
                  @click="!isUploadedImages && setIsUseGuidedUpload(false)"
                >
                  Basic upload
                  <div class="upload-switcher-tooltip" style="left: 0;">You must delete all uploaded photos to switch to the basic upload.</div>
                </div>
                <div
                  class="option"
                  :class="{ active: isUseGuidedUpload, disabled: isUploadedImages, 'show-tooltip': isUploadedImages && !checkReadonly }"
                  @click="!isUploadedImages && setIsUseGuidedUpload(true)"
                >
                  Guided upload
                  <div class="upload-switcher-tooltip" style="right: 0;">You must delete all uploaded photos to switch to guided upload.</div>
                </div>
              </div>
              <!-- Mobile alert banner -->
              <Banner iconName="alert_triangle_red" dismissible @dismissed="showImgSizeErr=false" bannerType="alert" v-if="showImgSizeErr && isMobile" class="photo-description font-weight-bold">
                One or more photos were NOT added.<br>Please upload images that are less than 20MB.
              </Banner>
              <Banner iconName="alert_triangle_red" dismissible @dismissed="showImgTypeErr=false" bannerType="alert" v-if="showImgTypeErr && isMobile" class="photo-description font-weight-bold">
                One or more photos were NOT added.<br>Please upload images in the following file types: jpeg, png, tiff.
              </Banner>
              <Banner iconName="alert_triangle_red" dismissible @dismissed="showMaxLengthErr=false" bannerType="alert" v-if="showMaxLengthErr && isMobile" class="photo-description font-weight-bold" :style="!showPhotoUploadBox ? 'margin-bottom: unset' : ''">
                One or more photos were NOT added.<br>Please upload up to {{ maxLength }} images.
              </Banner>
              <Banner iconName="alert_triangle_red" dismissible @dismissed="showMandatoryErr=false" bannerType="alert" v-if="showMandatoryErr && isMobile" class="photo-description font-weight-bold">
                Please add all of the 'Mandatory' photo angles to proceed.
              </Banner>
              <Banner iconName="alert_triangle_red" refreshable bannerType="alert" v-if="showNetworkIssueErr && isMobile" class="photo-description font-weight-bold">
                Network issue: Photo upload failed. Please check your network and try again.
              </Banner>
              <PhotoUploadBox
                v-if="!isUseGuidedUpload && showPhotoUploadBox"
                @uploadPhoto="uploadPhoto"
                :isMobile="isMobile"
                :handleDrop="drop"
                :imageDeleting="imageDeleting"
                :maxLength="maxLength"
                :errImgType="errImgType"
                :checkReadonly="checkReadonly"
                :initImgUploadCamera="initImgUploadCamera"
                :isSpecialDeviceBrowser="isSpecialDeviceBrowser"
              />
              <div class="upload-tips">
                <!-- Photo guide banner -->
                <Banner iconName="digital_camera_blue" v-if="!isUseGuidedUpload && !isUploadedImages" class="photo-description font-weight-bold">
                  Add at least 10 photos to increase your chance of a selling by 55%! <a :href="photoGuideLink.fromMyCar" target="_blank">Learn more</a>
                </Banner>
                <!-- Dynamic stats banner -->
                <Banner iconName="energy" v-else-if="uploadImage.length < 10 && !isUseGuidedUpload" class="photo-description font-weight-bold">
                  Psst! Add at least {{ photosNeeded }} more photos to increase your chance of selling by {{ photosUploadPercent }}.
                </Banner>
                <!-- Reminder banner -->
                <Banner iconClass="reminder-icon" v-if="!isUseGuidedUpload && isUploadedImages" class="photo-description font-weight-bold">
                  Don't forget to add photos of your car's odometer, tyres, and all pages of your service history.
                </Banner>
                <!-- Alert banner -->
                <Banner iconName="alert_triangle_red" dismissible @dismissed="showImgSizeErr=false" bannerType="alert" v-if="showImgSizeErr && !isMobile" class="photo-description font-weight-bold">
                  One or more photos were NOT added.<br>Please upload images that are less than 20MB.
                </Banner>
                <Banner iconName="alert_triangle_red" dismissible @dismissed="showImgTypeErr=false" bannerType="alert" v-if="showImgTypeErr && !isMobile" class="photo-description font-weight-bold">
                  One or more photos were NOT added.<br>Please upload images in the following file types: jpeg, png, tiff.
                </Banner>
                <Banner iconName="alert_triangle_red" dismissible @dismissed="showMaxLengthErr=false" bannerType="alert" v-if="showMaxLengthErr && !isMobile" class="photo-description font-weight-bold">
                  One or more photos were NOT added.<br>Please upload up to {{ maxLength }} images.
                </Banner>
                <Banner iconName="alert_triangle_red" dismissible @dismissed="showMandatoryErr=false" bannerType="alert" v-if="showMandatoryErr && !isMobile" class="photo-description font-weight-bold">
                  Please add all of the 'Mandatory' photo angles to proceed.
                </Banner>
                <Banner iconName="alert_triangle_red" refreshable bannerType="alert" v-if="showNetworkIssueErr && !isMobile" class="photo-description font-weight-bold">
                  Network issue: Photo upload failed. Please check your network and try again.
                </Banner>
              </div>
            </div>
            <MandatorySection
              v-show="isUseGuidedUpload"
              v-model="uploadImage"
              @handleImgUpload="handleImgUpload"
              @imageValidate="imageValidate"
              @deleteImg="deleteImg"
              @rotate="rotate"
              :isMobile="isMobile"
              :isSpecialDeviceBrowser="isSpecialDeviceBrowser"
              :isAccessingCamera="isAccessingCamera"
              :imageDeleting="imageDeleting"
              :checkReadonly="checkReadonly"
              @initImgUploadCamera="initImgUploadCamera"
            ></MandatorySection>
            <SectionDivider :class="isMobile ? 'mt-4' : ''" v-if="isUseGuidedUpload" dividerText="OPTIONAL"></SectionDivider>
            <div
              class="photo-div photo-div-optional"
              v-if="isUseGuidedUpload"
            >
              <ol class="photo-list wide-list p-0">
                <OptionalSection
                  v-model="uploadImage"
                  @handleImgUpload="handleImgUpload"
                  @imageValidate="imageValidate"
                  @deleteImg="deleteImg"
                  @rotate="rotate"
                  @uploadOtherPhoto="uploadPhoto"
                  :uploadImageOptionalCheck='this.uploadImageOptionalCheck'
                  :uploadImageOptionalLengthCheck='this.uploadImageOptionalCheck.length'
                  :imageDeleting='this.imageDeleting'
                  :isAccessingCamera="isAccessingCamera"
                  :checkReadonly="checkReadonly"
                  @initImgUploadCamera="initImgUploadCamera"
                >
                </OptionalSection>
              </ol>
            </div>
            <div
              v-if="!isUseGuidedUpload"
              class="position-relative d-flex flex-column align-items-center"
            >
              <div class="photo-div">
                <ol class="photo-list wide-list p-0">
                  <li
                    v-for="i in uploadImageCheck"
                    class="photo-list-item"
                    :class="[!i.isAutoplay ? 'item' : 'item-nodrag', i.err ? 'uploaded-fail' : '']"
                    :key="i.id"
                    style="position: relative;"
                    :style="checkReadonly || i.isAutoplay ? 'cursor: default;' : ''"
                  >
                    <div
                      class="image-container"
                      :class="{'deleted-mask': i.deleted}"
                    >
                      <b-overlay
                        :show="i.onUpload"
                        no-wrap
                        spinner-variant="success"
                        :opacity="0.5"
                      ></b-overlay>
                      <img
                        :class="{'img-upload-fail': i.err}"
                        :data-img-id="i.id"
                        width="100%"
                        :src="i.url"
                        @contextmenu="(e) => doNothing(e)"
                      />
                        <div v-if="i.err" class="upload-fail-text d-flex align-items-center">
                          <img src="@/assets/images/icons/ic_upload_fail.svg" class="upload-fail-icon" alt="">
                          <p class="font-weight-thin">Image upload failed</p>
                        </div>
                      <b-progress
                        v-if="i.progress && i.progress !== 100"
                        class="img-upload-progress"
                        :value="i.progress"
                        variant="success"
                        striped
                      ></b-progress>
                      <div class="edit-options-list" v-show="!i.onUpload" v-if="!i.err">
                        <v-touch v-show="!i.deleted && !checkReadonly" v-on:tap="i.isAutoplay ? addToDeleted(i.url) : deleteImg(i.filename, i.key)">
                          <custom-icon
                            name="delete"
                            class="edit-photo right"
                            width="100%"
                            :m0="true"
                          />
                        </v-touch>
                        <v-touch v-show="!i.isAutoplay && !checkReadonly">
                          <custom-icon
                            :data-img-id="i"
                            name="roll"
                            class="edit-photo left bottom-left"
                            width="100%"
                            @click="rotate(i)"
                          />
                        </v-touch>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
            <!-- <div class="nudge">
              <b-alert :show="showNudge && !this.isUseGuidedUpload" dismissible class="alert-heading px-4" :class="isMobile && 'shadow-lg'">
                <p class="heading"><img src="@/assets/images/icons/ic_bulblight.png" :width="isMobile ? '10px' : '12px'"> {{ quickTipsBanner.tipsHeader }}</p>
                <p>{{ quickTipsBanner.tipsContent }}</p>
              </b-alert>
            </div> -->
            <div class="container-question mt-4 text-left">
              <template v-for="(data, index) in questions">
                <div
                  :key="data.id"
                  class="d-flex justify-content-between align-items-center item"
                >
                  <u
                    class="mb-0 cursor-pointer"
                    :aria-controls="data.id"
                    :aria-expanded="data.isActive"
                    @click="active(index)"
                    v-html="data.question"
                  ></u>
                  <span @click="active(index)" :id="`quest-${index}`">
                    <img
                      :aria-controls="data.id"
                      :aria-expanded="data.isActive"
                      :src="require('@/assets/images/icons/ic_arrow_down.svg')"
                      :style="data.isActive ? 'transform: rotate(180deg)' : ''"
                    />
                  </span>
                </div>
                <b-collapse :id="data.id" :key="'answer-' + index" v-model="data.isActive">
                  <p class="quick-answer font-weight-thin" v-html="data.answer"></p>
                </b-collapse>
              </template>
              <div class="d-flex justify-content-between align-items-center item">
                <u 
                  aria-controls="report-issue"
                  :aria-expanded="isActiveReportIssue"
                  @click="activeReportIssue()"
                  class="mb-0 cursor-pointer"
                >
                  Need to report an issue?
                </u>
                <span @click="activeReportIssue()" id="quest-2">
                    <img
                      aria-controls="report-issue"
                      :aria-expanded="isActiveReportIssue"
                      :src="require('@/assets/images/icons/ic_arrow_down.svg')"
                      :style="isActiveReportIssue ? 'transform: rotate(180deg)' : ''"
                    />
                  </span>
              </div>
              <b-collapse id="report-issue" v-model="isActiveReportIssue">
                <p class="quick-answer font-weight-thin">
                  If you experience any issues with what you see on this page, please contact the {{ brandName }} team.<br>
                  <u v-b-modal.modal-issue style="cursor: pointer;">Report an issue</u>
                </p>
              </b-collapse>
            </div>
            <div class="position-relative">
              <btn-group
                @onCancel="goBack"
                @onSubmit="next"
                @onSave="openDialog"
                :disableSubmit="isButtonDisable"
                :disableOnUpload="onUpload"
                :query=this.$route.query
                :isMobile=isMobile
                :isLoadingApi="isLoadingApi"
                :customClasses="`${btnGroupPadding}`"
                submitText="Confirm"
                :isHavingUploadIssue="isHavingUploadIssue"
                :validateMandatory="!isAllMandatoryAdded"
              />
            </div>
            <div :style="isMobile ? 'height: 50px;' : 'height: 10px;'"></div>
          </div>
        </div>
      </div>
      <!-- <b-modal id="init-upload-modal" hide-footer modal-class="modal-init-upload">
        <template #modal-header>
          <b-button
            type="button"
            aria-label="Close"
            variant="outline-light"
            pill
            style="border: 0; font-size: 25px; position: absolute; right: 0"
            @click="!isAccessingCamera && $bvModal.hide('init-upload-modal')"
          >
            <b-icon icon="x-circle"></b-icon>
          </b-button>
        </template>
        <div class="d-block text-center">
          <div class="upload-option-wrapper">
            <b-overlay
              :show="isAccessingCamera"
              rounded
              opacity="0.6"
              spinner-small
              class="d-inline-block"
            >
              <div
                class="upload-option upload-camera"
                @click="initImgUploadCamera"
              >
                <div>
                  <b-icon class="option-icon" icon="camera-fill"></b-icon>
                </div>
                <div><b>Guided camera mode</b></div>
              </div>
            </b-overlay>

            <div
              class="upload-option upload-file"
              @click="!isAccessingCamera && initImgUploadFile()"
            >
              <div><b-icon class="option-icon" icon="images"></b-icon></div>
              <div><b>Folder</b></div>
            </div>
          </div>
        </div>
      </b-modal> -->
      <b-modal
        no-close-on-backdrop
        hide-header
        hide-footer
        id="camera-modal"
        modal-class="camera-modal"
        ref="cameraModal"
      >
        <BlockScreen class="display-when-portrait" :closeAnyWay="closeBlockScreen" :close="closeCamera"></BlockScreen>
        <div class="camera-screen">
          <video
            ref="cameraView"
            class="camera-view"
            autoplay
            playsinline
            muted
          ></video>
          <div v-if="showCameraOption" class="confirm-option-wrapper d-flex justify-content-center text-center">
            <div
              class="cursor-pointer confirm-option take-again"
              @click="takePhotoAgain"
            >
              <b class="confirm-text">Try Again</b>
              <b-icon icon="arrow-repeat" class="confirm-icon"></b-icon>
            </div>
            <b-overlay
              :show="isSavingCameraPhoto"
              rounded
              opacity="0.6"
              spinner-small
              class="d-inline-block overlay"
            >
              <div
                class="cursor-pointer confirm-option use-this-photo"
                @click="useThisPhoto"
              >
                <b class="confirm-text">Use This Photo</b>
                <b-icon icon="check" class="confirm-icon"></b-icon>
              </div>
            </b-overlay>
          </div>
          <div v-if="showCameraOption && isClosing" class="confirm-option-wrapper d-flex justify-content-center text-center">
            <div
              class="cursor-pointer confirm-option take-again"
              @click="discardAndExit"
            >
              <b class="confirm-text">Discard Photos</b>
              <!-- <b-icon icon="arrow-repeat" class="confirm-icon"></b-icon> -->
            </div>
            <b-overlay
              :show="isSavingCameraPhoto"
              rounded
              opacity="0.6"
              spinner-small
              class="d-inline-block overlay"
            >
              <div
                class="cursor-pointer confirm-option use-this-photo"
                @click="saveAndExit"
              >
                <b class="confirm-text">Save</b>
                <!-- <b-icon icon="check" class="confirm-icon"></b-icon> -->
              </div>
            </b-overlay>
          </div>

          <Tooltips v-if="showHelpGuide"/>
          
          <div v-if="currentCapturePhoto.frame" id="camera-outlay">
            <img
              :src="require(`@/assets/car-frames/${currentCapturePhoto.frame}`)"
              alt=""
            />
          </div>
          <canvas ref="cameraOutput" id="camera-output" @click="closeDropdown"></canvas>
          <div class="function-section">
            <custom-icon name="capture" class="shoot-btn" width="100%" :m0="true" @click="!isSavingCameraPhoto && takePhoto()"/>
            <div class="button-wrapper">
              <div class="under-btn">
                <b-button
                  class="body-type-btn"
                  @click="!isSavingCameraPhoto && !showCameraOption && toggleBodyTypePanel()"
                >
                  <custom-icon name="body_type" width="100%" :m0="true"/>
                </b-button>
                <b-button
                  class="help-btn"
                  :class="{ active: showHelpGuide }"
                  @click="!isSavingCameraPhoto && !showCameraOption && openHelpGuide()"
                >
                  <b-icon class="shoot-btn-icon" icon="question"></b-icon
                ></b-button>
              </div>
              <div class="upper-btn">
                <b-button
                  class="close-btn"
                  @click="!isSavingCameraPhoto && !showCameraOption ? exitCamera() : enableExitConfirmDialog()"
                >
                <b-icon v-if="this.uploadImage.length >= 1" icon="check2" scale="2" ></b-icon>
                  <custom-icon v-else name="close" width="100%" :m0="true"/>
                </b-button>
              </div>
            </div>
          </div>
          <div class="body-type-dropdown" v-if="showBodyTypePanel">
            <ul>
              <li v-for="(carBodyType, index) in bodyTypes" :key="index">
                <div class="option" @click="updateBodyType(carBodyType)">
                  <div class="body-type-name">
                    {{ carBodyType }}
                  </div>
                  <div class="body-type-status">
                    <b-icon v-if="carBodyType === curBodyType" icon="check" scale="2"></b-icon>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="photo-frame-wrapper">
            <div class="photo-frame-dropdown">
              <div class="photo-image-list-wrapper" v-if="showFrameSelection">
                <ul>
                  <li v-for="(photo, index) in photoOrdersFiltered" :key="index"  @click="setCurrentPhoto(index)">
                    <div class="option">
                      <div class="frame-type">
                        {{ photo.type }}
                      </div>
                      <div class="frame-name">
                        {{ photo.title }}
                      </div>
                      <div class="frame-status">
                        <b-icon v-if="photo.haveImg" icon="check" scale="2"></b-icon>
                        <b-icon v-else icon="dot" scale="3"></b-icon>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="photo-frame-header">
                <div class="screenshot-title" v-show="showTitle">{{ getCurrentCapturePhotoFrameTitle() }}</div>
                <div class="select-angle-btn" @click="!isSavingCameraPhoto && !showCameraOption && openFrameSelection()">
                  SELECT 
                  <br>
                  ANGLE
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import PhotoUploadService from "@/services/photo-upload";
import mixin from "@/mixins/common";
import { promisePool } from "@/helper/promise-pool";
import { Fullscreen } from "@/helper/fullscreen";
import { MAX_FILE_SIZE, MAX_UPLOAD_FILE_SIZE, EXTENSIONS, socialNetworkBrowser, browserList } from "@/helper/constant";
import { SET_INFO } from "@/store/info/actions";
import heicConvert from "heic-convert";
import { mandatoryList, optionalList } from '@/config/constants';
import { UPDATE_DATA, SET_DELETED_IMAGES } from "@/store/authenticate-seller/actions";
import { logError, tiffToBlob, resizeImage } from "@/helper/utils";

export default {
  name: "Index",
  mixins: [mixin],
  components: {
    CustomIcon: () => import("../../components/common/CustomIcon"),
    BtnGroup: () => import("../../components/common/BtnGroup.vue"),
    Tooltips: () => import("../../components/photo-upload/Tooltips.vue"),
    BlockScreen: () => import("../../components/photo-upload/BlockScreen.vue"),
    NoPhotos: () => import("../../components/photo-upload/NoPhotos.vue"),
    MandatorySection: () => import("../../components/photo-upload/MandatorySection.vue"),
    OptionalSection: () => import("../../components/photo-upload/OptionalSection.vue"),
    SectionDivider: () => import("../../components/common/SectionDivider.vue"),
    CustomDialog: () => import("../../components/common/CustomDialog"),
    ListedCarMessage: () => import("../../components/common/ListedCarMessage.vue"),
    Banner: () => import("../../components/photo-upload/Banner.vue"),
    PhotoUploadBox: () => import("../../components/photo-upload/PhotoUploadBox.vue"),
  },
  data() {
    return {
      uploadImage: [],
      newFiles: [],
      dragCounter: 0,
      max: MAX_FILE_SIZE,
      errImgSize: [],
      maxLength: 30,
      errImgType: [],
      minLength: 1,
      onUpload: false,
      showGuideline: false,
      showCameraOption: false,
      curBodyType: "Sedan",
      curFrame: "",
      isAccessingCamera: false,
      isSavingCameraPhoto: false,
      cameraStream: null,
      cameraConstraints: {
        video: {
          facingMode: "environment",
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
        audio: false,
      },
      showImgSizeErr: false,
      showImgTypeErr: false,
      showMaxLengthErr: false,
      showHelpGuide: false,
      showBodyTypePanel: false,
      showFrameSelection: false,
      isClosing: false,
      checkingImg: {
        // exteriorCoverShot: false,
        exteriorFront: false,
        exteriorFrontSideL: false,
        exteriorSideL: false,
        exteriorRearSideL: false,
        exteriorRear: false,
        exteriorRearSideR: false,
        exteriorSideR: false,
        exteriorFrontSideR: false,
        exteriorTyre: false,
        exteriorRoof: false,
        interiorMeter: false,
        interiorDashboard: false,
        interiorSteeringWheel: false,
        interiorFrontSeat: false,
        interiorBackSeat: false,
        interiorThirdRowSeating: false,
        interiorBoot: false,
        interiorKey: false,
        interiorOwnerManual: false,
        interiorServiceHistory: false,
        interiorOther: false,
      },
      imageDeleting: false,
      showTitle: true,
      isLandscape: false,
      isMobile: true,
      isSpecialDeviceBrowser: false,
      regoInfo: this.$store.getters.regoSearch,
      isLoadingApi: false,
      isActiveReportIssue: true,
      questions: [
        {
          id: 'question-1',
          question: `Why is my registration plate hidden?`,
          answer: '{brandName} hides your registration plate to protect your privacy from potential buyers.',
          isActive: false,
        },
        {
          id: 'question-2',
          question: `Why can't I delete certain photos?`,
          answer: `Photos that were previously uploaded can't be deleted on your own. If you wish to remove any of these photos, please contact us.`,
          isActive: false,
        }
      ],
      customerName: this.$store.getters.customerName,
      photoUploadActionLogs: {
        totalCount: {
          add: 0,
          delete: 0
        }
      },
      showMandatoryErr: false,
      isDialogOpen: false,
      isCurrPageChanges: false,
      frameKey: '',
      showPhotoUploadBox: true,
      showNetworkIssueErr: false,
    };
  },
  computed: {
    ...mapState({
      steps: (state) => state.common.steps,
      info: (state) => state.info.curVehicleInfo,
      bodyTypes: (state) => state.info.bodyTypes,
      stateImgs: (state) => state.photo.uploadImage,
      selfServiceConfig: (state) => state.selfServiceQualifier.config,
      authenticateData: (state) => state.authenticateSeller,
      hasPhoto: (state) => state.photo.hasPhoto,
      isIdle: (state) => state.common.isIdle,
      photoGuideLink: (state) => state.commonVariables.photoGuideLink,
      copyBlocks: (state) => state.commonVariables.copyBlocks,
      quickTipsBanner: (state) => state.commonVariables.copyBlocks.quickTipsBanner,
      theme: (state) => state.commonVariables.theme,
      brand: (state) => state.commonVariables.brandName,
      isUseGuidedUpload: (state) => state.photo.isUseGuidedUpload,
    }),
    ...mapGetters({
      hasGotQuoteResponse: "hasGotQuoteResponse",
    }),
    notUploadItems: function () {
      return this.isMobile
        ? this.uploadImage.length < 6 && !this.isUseGuidedUpload
          ? 6 - this.uploadImage.length
          : 0
        : this.uploadImage.length < 9 && !this.isUseGuidedUpload
        ? 9 - this.uploadImage.length
        : 0;
    },
    currentCapturePhoto() {
      return this.curFrame;
    },
    currentUploadPhoto() {
      return this.photoOrdersFiltered[this.uploadImage.length] ?? {};
    },
    curBodyTypeFormat: function() {
      let string = this.curBodyType.toLowerCase()
      if (this.curBodyType === 'People mover/Van'){
        return string = 'Mover';
      } else {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    },
    photoOrders() {
      return [
        // {
        //   frame: `Exterior/${this.curBodyType}/${this.curBodyType}_Front_Side_Left_Transparent.svg`,
        //   title: "COVER SHOT",
        //   type: "EXTERIOR",
        //   name: "exterior cover shot",
        //   key: "exteriorCoverShot",
        //   haveImg: this.checkingImg.exteriorCoverShot,
        // },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Front.svg`,
          title: "FRONT",
          type: "EXTERIOR",
          name: "exterior front",
          key: "exteriorFront",
          haveImg: this.checkingImg.exteriorFront,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Front_Side_Left_Transparent.svg`,
          title: "3/4 FRONT (LEFT)",
          type: "EXTERIOR",
          name: "exterior front side l",
          key: "exteriorFrontSideL",
          haveImg: this.checkingImg.exteriorFrontSideL,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Side_Left.svg`,
          title: "SIDE (LEFT)",
          type: "EXTERIOR",
          name: "exterior side l",
          key: "exteriorSideL",
          haveImg: this.checkingImg.exteriorSideL,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Back_Side_Left.svg`,
          title: "3/4 REAR (LEFT)",
          type: "EXTERIOR",
          name: "exterior rear side l",
          key: "exteriorRearSideL",
          haveImg: this.checkingImg.exteriorRearSideL,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Back.svg`,
          title: "REAR",
          type: "EXTERIOR",
          name: "exterior rear",
          key: "exteriorRear",
          haveImg: this.checkingImg.exteriorRear,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Back_Side_Right.svg`,
          title: "3/4 REAR (RIGHT)",
          type: "EXTERIOR",
          name: "exterior rear side r",
          key: "exteriorRearSideR",
          haveImg: this.checkingImg.exteriorRearSideR,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Side_Right.svg`,
          title: "SIDE (RIGHT)",
          type: "EXTERIOR",
          name: "exterior side r",
          key: "exteriorSideR",
          haveImg: this.checkingImg.exteriorSideR,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Front_Side_Right_Transparent.svg`,
          title: "3/4 FRONT (RIGHT)",
          type: "EXTERIOR",
          name: "exterior front side r",
          key: "exteriorFrontSideR",
          haveImg: this.checkingImg.exteriorFrontSideR,
        },
        {
          frame: "Exterior/Car_tyre.svg",
          title: "CAR TYRE",
          type: "EXTERIOR",
          name: "exterior tyre",
          notSendToQuote: true,
          key: "exteriorTyre",
          haveImg: this.checkingImg.exteriorTyre,
        },
        {
          frame: "Exterior/Car_roof.svg",
          title: "CAR ROOF",
          type: "EXTERIOR",
          name: "exterior roof",
          notSendToQuote: true,
          key: "exteriorRoof",
          haveImg: this.checkingImg.exteriorRoof,
        },
        {
          frame: "Interior/ODOMETER.svg",
          title: "ODOMETER",
          type: "INTERIOR",
          name: "interior meter",
          notSendToQuote: true,
          key: "interiorMeter",
          haveImg: this.checkingImg.interiorMeter,
        },
        {
          frame: "Interior/DASHBOARD.svg",
          title: "DASHBOARD",
          type: "INTERIOR",
          name: "interior dash",
          notSendToQuote: true,
          key: "interiorDashboard",
          haveImg: this.checkingImg.interiorDashboard,
        },
        {
          frame: "Interior/Steering_wheel.svg",
          title: "STEERING WHEEL",
          type: "INTERIOR",
          name: "interior steering wheel",
          notSendToQuote: true,
          key: "interiorSteeringWheel",
          haveImg: this.checkingImg.interiorSteeringWheel,
        },
        {
          frame: "Interior/FRONT SEAT.svg",
          title: "FRONT SEAT",
          type: "INTERIOR",
          name: "interior front seat",
          notSendToQuote: true,
          key: "interiorFrontSeat",
          haveImg: this.checkingImg.interiorFrontSeat,
        },
        {
          frame: "Interior/BACK SEAT.svg",
          title: "BACK SEAT",
          type: "INTERIOR",
          name: "interior back seat",
          notSendToQuote: true,
          key: "interiorBackSeat",
          haveImg: this.checkingImg.interiorBackSeat,
        },
        {
          frame: "Interior/3RD ROW SEAT.svg",
          title: "3RD ROW SEAT",
          type: "INTERIOR",
          name: "interior third row seating",
          notSendToQuote: true,
          key: "interiorThirdRowSeating",
          haveImg: this.checkingImg.interiorThirdRowSeating,
        },
        {
          frame: `Interior/Boot - ${this.curBootFrame}.svg`,
          title: "BOOT",
          type: "INTERIOR",
          name: "boot",
          notSendToQuote: true,
          key: "interiorBoot",
          haveImg: this.checkingImg.interiorBoot,
        },
        {
          frame: "Interior/KEYS.svg",
          title: "KEYS",
          type: "INTERIOR",
          name: "key",
          notSendToQuote: true,
          key: "interiorKey",
          haveImg: this.checkingImg.interiorKey,
        },
        {
          frame: "",
          title: "OWNER'S MANUAL",
          type: "INTERIOR",
          name: "interior owner's manual",
          notSendToQuote: true,
          key: "interiorOwnerManual",
          haveImg: this.checkingImg.interiorOwnerManual,
        },
        {
          frame: "",
          title: "SERVICE HISTORY",
          type: "INTERIOR",
          name: "interior service history",
          notSendToQuote: true,
          key: "interiorServiceHistory",
          haveImg: this.checkingImg.interiorServiceHistory,
        },
        {
          frame: "",
          // frame: "Interior/Other.svg",
          title: "OTHER",
          type: "INTERIOR",
          name: "interior other",
          notSendToQuote: true,
          key: "interiorOther",
          haveImg: this.checkingImg.interiorOther,
        },
      ]
    },
    bodyTypes() {
      return ['Sedan', 'SUV', 'Hatchback', 'Ute', 'Wagon', 'Coupe', 'Convertible', 'People mover/Van'];
    },
    photoOrdersFiltered() {
      const thirdRowSeatingfilteredType = ['SUV', 'People mover/Van'];
      let tempFilteredList = [];
      if(!thirdRowSeatingfilteredType.includes(this.curBodyType)) {
        this.photoOrders.forEach((photo) => {
          photo.key !== 'interiorThirdRowSeating' && tempFilteredList.push(photo);
        })
      } else {
        tempFilteredList = [...this.photoOrders];
      }
      return tempFilteredList;
    },
    curBootFrame() {
      const hasSmallBoot = ['Sedan', 'Convertible'];
      let curBootSVGType = '';
      if(hasSmallBoot.includes(this.curBodyType)) {
        curBootSVGType = 'Sedan';
      } else {
        curBootSVGType = 'SUV NEW';
      }
      return curBootSVGType || "";
    },
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 900px)");
    },
    showNudge() {
      return this.uploadImage.length >= 10 || this.uploadImage.length === 0;
    },
    isUploadedImages() {
      return this.uploadImage.length > 0;
    },
    photosNeeded() {
      return 10 - this.uploadImage.length;
    },
    photosUploadPercent() {
      return this.photosNeeded < 6 ? "33%" : "98%";
    },
    autoplayImagesLength() {
      return this.uploadImage.filter(item => item.isAutoplay).length;
    },
    isButtonDisable() {
      if (this.isUseGuidedUpload) {
        return !this.isAllMandatoryAdded;
      } else {
        return this.uploadImage.filter(item => !item.isAutoplay).length === 0;
      }
    },
    isAllMandatoryAdded() {
      if (this.isUseGuidedUpload) {
        for (const item of mandatoryList) {
          if (!this.uploadImage.find((image) => image.key === item.key)) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    btnGroupPadding() {
      if (!this.isMobile) {
        if (this.uploadImage.length === 0) return this.isUseGuidedUpload ? 'custom-padding-2' : 'custom-padding-1';
        else return 'custom-padding-2';
      }
      return '';
    },
    uploadImageCheck() {
      return this.isUseGuidedUpload
        ? this.uploadImageOptionalCheck
        : this.uploadImage
    },
    uploadImageMandatoryCheck() {
      const uploadImageMandatory = [];
      for (const item of mandatoryList) {
        this.uploadImage.forEach((image) => {
          if (image.key === item.key) uploadImageMandatory.push(image);
        }) 
      }
      return uploadImageMandatory;
    },
    uploadImageOptionalCheck() {
      const uploadImageOptional = [];
      if (this.uploadImageMandatoryCheck.length === 0) {
        uploadImageOptional.push(...this.uploadImage);
      } else {
          const mandatoryKeys = this.uploadImageMandatoryCheck.map((item) => item.key);
          const optionals = this.uploadImage.filter(image => !mandatoryKeys.includes(image.key));
          uploadImageOptional.push(...optionals);
      }
      return uploadImageOptional.slice(0, 22);
    },
    separatesUploadImages: function() {
      const images = [];
      const oldImages = [];
      this.uploadImage.forEach(image => {
        if (image.isAutoplay) {
          oldImages.push(image);
        } else {
          images.push(image);
        }
      })
      return { images, oldImages };
    },
    checkReadonly: function() {
      return this.authenticateData.listed || this.authenticateData.readonlyMode;
    },
    brandName() {
      return this.brand || "Autoflip";
    },
    isHavingUploadIssue() {
      return this.uploadImage.length > 0 && this.uploadImage.every(image => Object.hasOwnProperty.call(image, "err"));
    },
  },
  methods: {
    ...mapActions({
      setIsUseGuidedUpload: 'setIsUseGuidedUpload',
    }),
    useThisPhoto() {
      this.isSavingCameraPhoto = true;
      this.$refs.cameraOutput.toBlob(
        async (blob) => {
          const imgId = this.$uuid.v4();
          this.uploadImage = [
            ...this.uploadImage,
            {
              id: imgId,
              filename: this.currentCapturePhoto.name,
              name: this.currentCapturePhoto.name,
              title: this.currentCapturePhoto.title,
              type: this.currentCapturePhoto.type,
              key: this.currentCapturePhoto.key,
              notSendToQuote: this.currentCapturePhoto.notSendToQuote,
              url: null,
              onUpload: true,
              progress: 0,
              isAutoplay: false,
            },
          ];
          this.handleImgUpload(blob, imgId);
          this.isSavingCameraPhoto = false;
          this.showCameraOption = false;
          this.checkingImg[this.currentCapturePhoto.key] = true;
          this.nextFrame();
          this.clearVideoOutput();
          if (this.uploadImage.length === this.maxLength) {
            this.$bvModal.hide("camera-modal");
            this.cameraStream.getTracks().forEach((track) => track.stop());
          }
        },
        "image/jpeg",
        1
      );
      this.$store.dispatch("setUploadedImages", this.uploadImage);
      this.$store.dispatch("setTakePhotoWithGuidedCamera", true);
    },
    takePhotoAgain() {
      this.clearVideoOutput();
      this.showCameraOption = false;
    },
    takePhoto() {
      this.showBodyTypePanel = false;
      this.showHelpGuide = false;
      this.showFrameSelection = false;
      const { w, h } = this.getVideoSize(this.$refs.cameraView);
      this.$refs.cameraOutput.width = w;
      this.$refs.cameraOutput.height = h;
      this.$refs.cameraOutput
        .getContext("2d")
        .drawImage(this.$refs.cameraView, 0, 0);
      this.showCameraOption = true;
    },
    exitCamera() {
      this.$bvModal.hide("camera-modal");
      this.clearVideoOutput();
      this.cameraStream.getTracks().forEach((track) => track.stop());
      this.showFrameSelection = false;
      this.showGuideline = false;
      this.showBodyTypePanel = false;
      this.toggleChatSection();
    },
    async initImgUploadCamera() {
      try {
        this.updateUrl()
        this.$store.dispatch('setGuideCameraUsed', true);
        this.isAccessingCamera = true;
        this.$store.dispatch('hasPhoto', true);
        this.cameraStream = await navigator.mediaDevices.getUserMedia(
          this.cameraConstraints
        );
        this.isAccessingCamera = false;
        this.$bvModal.show("camera-modal");
        this.$nextTick(() => {
          new Fullscreen(document.querySelector("#camera-modal")).toggle();
          this.$refs.cameraView.srcObject = this.cameraStream;
        });
        const chatSection = document.getElementsByTagName('mmgp-sms-chat-widget')[0];
        if (chatSection) {
          chatSection.style.display = "none";
        }
      } catch (err) {
        this.isAccessingCamera = false;
        this.$toast.error("Unable to access camera");
        logError(`Unable to access camera: ${err}`);
      }
    },
    updateUrl() {
      this.$router.replace({ path: '/photo/upload', query: this.$route.query });
    },
    getVideoSize(ele) {
      const w = ele.videoWidth;
      const h = ele.videoHeight;
      return { w, h };
    },
    clearVideoOutput() {
      this.$refs.cameraOutput
        .getContext("2d")
        .clearRect(
          0,
          0,
          this.$refs.cameraOutput.width,
          this.$refs.cameraOutput.height
        );
    },
    async goBack() {
      await this.$store.dispatch("setUploadedImages", this.uploadImage);
      if (this.authenticateData.imageUrls.length > 0 || this.uploadImage.length > 0 || this.isSpecialDeviceBrowser) {
        this.$router.push({ name: "navigation", query: this.$route.query });
      } else {
        this.$store.dispatch('hasPhoto', !this.hasPhoto);
        this.$router.replace({ path: '/photo', query: this.$route.query });
      }
    },
    async next() {
      await this.$store.dispatch("setUploadedImages", this.uploadImage);
      if (this.isButtonDisable && !this.isUseGuidedUpload) {
          this.isLoadingApi = false;
          return;
        }
      if (!this.isButtonDisable || !this.isUseGuidedUpload || this.mandatoryCheck()) {
        this.$router.push({ name: "navigation", query: this.$route.query });
      }
    },
    async rotate(i) {
      this.onUpload = true;
      this.uploadImage = this.uploadImage.map((item) => {
        if (item.id !== i.id) return item;
        else {
          return {
            ...item,
            onUpload: true,
          };
        }
      });
      try {
        const newImg = await PhotoUploadService.rotate(i);
        if (Object.hasOwnProperty.call(newImg, "err")) {
          this.showNetworkIssueErr = true;
          this.uploadImage = this.uploadImage.map((item) => {
            if (item.id !== i.id) return item;
            else {
              return {
                ...item,
                onUpload: false,
              };
            }
          });
        }
        this.uploadImage = this.uploadImage.map((item) => {
          if (item.id !== i.id) return item;
          else {
            return {
              ...item,
              ...newImg,
              id: i.id,
              onUpload: false,
            };
          }
        });
      } catch (err) {
        this.$toast.error("Too many requests");
        logError(`Too many requests: ${err}`);
      }
      this.onUpload = false;
      this.$store.dispatch("setUploadedImages", this.uploadImage);
    },
    convertImageMimeType: async function(imageFile, predominantColorsDetected, checkers) {
      try {
        checkers.push(() =>
          this.checkPredominantColors(
            imageFile,
            "user's uploaded file",
            predominantColorsDetected
          )
        );
        const fileExt = (imageFile.name || "").split(".").pop();
        if (/(tiff|tif)/gi.test(fileExt)) {
          const tiffBlob = await tiffToBlob(imageFile);
          checkers.push(() =>
            this.checkPredominantColors(
              tiffBlob,
              "tiff|tif converter",
              predominantColorsDetected
            )
          );
          return tiffBlob;
        } else if (/(heic)/gi.test(fileExt)) {
          const blob = await imageFile.arrayBuffer();
          const buffer = Buffer.from(blob);
          const jpgBuffer = await heicConvert({
            buffer,
            format: "JPEG",
            quality: 1,
          });
          const outputFile = new File([jpgBuffer], "output.jpg", {
            type: "image/jpeg",
          });
          checkers.push(() =>
            this.checkPredominantColors(
              outputFile,
              "heic converter",
              predominantColorsDetected
            )
          );
          return outputFile;
        }
        return imageFile;
      } catch (err) {
        logError(`convertImageMimeType error: ${err}`);
        return imageFile;
      }
    },
    handleImgUpload: async function(file, imgId) {
      try {
        let img;
        let predominantColorsDetected = {};
        const checkers = [];
        const imageFile = await this.convertImageMimeType(
          file,
          predominantColorsDetected,
          checkers,
        );
        if (imageFile.size > MAX_UPLOAD_FILE_SIZE) {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            maxIteration: 20,
            fileType: "image/jpeg",
          };
          img = await resizeImage(imageFile, options);
          checkers.push(() =>
            this.checkPredominantColors(
              img,
              "image resizer",
              predominantColorsDetected
            )
          );
        } else {
          img = imageFile;
        }
        const blobToUrl = URL.createObjectURL(img);
        this.uploadImage = this.uploadImage.map((stateImge) =>
          stateImge.id === imgId
            ? {
                ...stateImge,
                url: blobToUrl,
              }
            : stateImge
        );
        let formData = new FormData();
        formData.append("image", img);
        formData.append("predominantColorsDetected", Object.values(predominantColorsDetected).includes(true));
        this.onUpload = true;
        const uploadedImgData = await PhotoUploadService.uploadImage(
          formData,
          imgId,
          (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.uploadImage = this.uploadImage.map((stateImge) =>
              stateImge.id === imgId
                ? {
                    ...stateImge,
                    progress,
                  }
                : stateImge
            );
          }
        );
        URL.revokeObjectURL(blobToUrl);
        if (uploadedImgData?.isPredominantColorsImage && !Object.values(predominantColorsDetected).includes(true)) {
          checkers.push(() =>
            this.logPredominantColorsError(
              "uploaded image in S3",
              uploadedImgData,
            )
          );
        }
        this.runCheckersSequentially(checkers);
        if (Object.hasOwnProperty.call(uploadedImgData, "networkError")) {
          this.showNetworkIssueErr = true;
          this.uploadImage = this.uploadImage.filter((img) => !img.onUpload && !Object.hasOwnProperty.call(img, "err"));
          this.onUpload = false;
          this.$store.dispatch("setUploadedImages", this.uploadImage);
          throw new Error('Network Error');
        }
        this.uploadImage = this.uploadImage
          .filter((img) => {
            return !Object.hasOwnProperty.call(uploadedImgData, "err")
              ? !Object.hasOwnProperty.call(img, "err")
              : imgId !== img.id;
          })
          .map((exImg) => {
            return imgId !== exImg.id
              ? exImg
              : {
                  ...exImg,
                  ...uploadedImgData,
                  onUpload: false,
                  progress: 100,
                };
          });
        this.onUpload = false;
        this.$store.dispatch("setUploadedImages", this.uploadImage);
      } catch (err) {
        if (`${err}`.includes('Network Error')) {
          throw new Error("Network Error");
        } else {
          logError(`handleImgUpload error: ${err}`);
        }
      }
    },
    uploadPhoto: async function (event) {
      var input = event.target;
      const promiseBatch = [];
      this.errImgSize = [];
      this.errImgType = [];
      this.showMaxLengthErr = false;
      this.showImgSizeErr = false;
      this.showImgTypeErr = false;
      this.showPhotoUploadBox = this.uploadImage.length + input.files.length < this.maxLength;
      if (this.uploadImage.length === this.maxLength) {
        this.showMaxLengthErr = true;
      }
      var count_file = this.uploadImage.length;
      if (input.files && this.uploadImage.length < this.maxLength) {
        this.onUpload = true;
        Array.from(input.files).forEach((file) => {
          if (!EXTENSIONS.includes(file.name.split('.').pop().toLowerCase())) {
            this.errImgType = [...this.errImgType, file.name];
          } else if (file.size > this.max) {
            this.errImgSize = [...this.errImgSize, file.name];
          } else if (
            (this.isUseGuidedUpload &&
              this.uploadImageOptionalCheck.length < 22) ||
            (!this.isUseGuidedUpload && this.uploadImage.length < this.maxLength)
          ) {
            const imgId = this.$uuid.v4();
            this.nextFrame();
            this.checkingImg[this.curFrame.key] = true;
            this.uploadImage = [
              ...this.uploadImage,
              {
                id: imgId,
                filename: file.name,
                url: null,
                name: file.name,
                key:
                  this.isUseGuidedUpload
                    ? optionalList[this.uploadImageOptionalCheck.length]?.key
                    : this.photoOrdersFiltered[this.uploadImage.length]?.key,
                title:
                  this.isUseGuidedUpload
                    ? optionalList[this.uploadImageOptionalCheck.length]?.title || 'OTHER'
                    : this.photoOrdersFiltered[this.uploadImage.length]?.title,
                type:
                  this.isUseGuidedUpload
                    ? optionalList[this.uploadImageOptionalCheck.length]?.type || 'INTERIOR'
                    : this.photoOrdersFiltered[this.uploadImage.length]?.type,
                onUpload: true,
                progress: 0,
                isAutoplay: false,
              },
            ];
            promiseBatch.push(() => this.handleImgUpload(file, imgId));
          }
          count_file = count_file + 1 ;
        });
        if (this.errImgSize.length > 0) {
          this.showPhotoUploadBox = true;
          this.showImgSizeErr = true;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          this.showImgSizeErr = false
        }
        if (this.errImgType.length > 0) {
          this.showPhotoUploadBox = true;
          this.showImgTypeErr = true;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          this.showImgTypeErr = false
        }
        await promisePool(promiseBatch, 3);
        this.onUpload = false;
        event.target.value = "";
      }
      if (count_file > this.maxLength) {
        event.target.value = "";
        this.showMaxLengthErr = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      this.$store.dispatch("setUploadedImages", this.uploadImage);
    },
    drop: async function(e) {
      this.doNothing(e);
      this.showPhotoUploadBox = this.uploadImage.length + e.dataTransfer.items.length < this.maxLength;
      if (this.uploadImage.length === this.maxLength) {
        this.showMaxLengthErr = true;
      }
      const uploadJob = [];
      var count_file = this.uploadImage.length;
      const handleUploadFile = (img) => {
        if (!EXTENSIONS.includes(img.name.split(".").pop().toLowerCase())) {
          this.errImgType = [...this.errImgType, img.name];
        } else if (img.size > this.max) {
          this.errImgSize = [...this.errImgSize, img.name];
        } else if (this.uploadImage.length < this.maxLength) {
          const imgId = this.$uuid.v4();
          this.uploadImage = [
            ...this.uploadImage,
            {
              id: imgId,
              imgname: img.name,
              title: this.currentPhoto?.title,
              url: null,
              onUpload: true,
              progress: 0,
            },
          ];
          uploadJob.push(() => this.handleImgUpload(img, imgId));
        }
      };
      async function getFile(fileEntry) {
        try {
          return new Promise((resolve, reject) =>
            fileEntry.file(resolve, reject)
          );
        } catch (err) {
          logError(`Get file error: ${err}`);
        }
      }
      function traverse_directory(entry) {
        let reader = entry.createReader();
        return new Promise((resolve_directory) => {
          let iteration_attempts = [];
          (function read_entries() {
            reader.readEntries((entries) => {
              if (!entries.length) {
                resolve_directory(Promise.all(iteration_attempts));
              } else {
                iteration_attempts.push(
                  entries.map(async (entry) => {
                    if (entry.isFile) {
                      const file = await getFile(entry);
                      handleUploadFile(file);
                      return file;
                    } else {
                      return traverse_directory(entry);
                    }
                  })
                );
                read_entries();
              }
            });
          })();
        });
      }
      // upload folder
      if (
        this.uploadImage.length < this.maxLength &&
        e.dataTransfer.items.length > 0
      ) {
        this.onUpload = true;
        this.errImgType = [];
        this.errImgSize = [];
        this.showImgSizeErr = false;
        this.showImgTypeErr = false;
        Array.from(e.dataTransfer.items).forEach(async (item, idx) => {
          const entry = item.webkitGetAsEntry();
          if (entry != null) {
            if (entry.isDirectory) {
              traverse_directory(entry).then(async () => {
                await promisePool(uploadJob, 3);
                e.target.value = "";
                if (this.errImgSize.length > 0) {
                  this.showPhotoUploadBox = true;
                  this.showImgSizeErr = true;
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                } else {
                  this.showImgSizeErr = false;
                }
                if (this.errImgType.length > 0) {
                  this.showPhotoUploadBox = true;
                  this.showImgTypeErr = true;
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                } else {
                  this.showImgTypeErr = false;
                }
              });
            }
            if (entry.isFile) {
              handleUploadFile(e.dataTransfer.files[idx]);
              count_file = count_file + 1;
            }
          }
        });
        await promisePool(uploadJob, 3);
        this.onUpload = false;
        e.target.value = "";
        if (this.errImgSize.length > 0) {
          this.showPhotoUploadBox = true;
          this.showImgSizeErr = true;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          this.showImgSizeErr = false;
        }
        if (this.errImgType.length > 0) {
          this.showPhotoUploadBox = true;
          this.showImgTypeErr = true;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          this.showImgTypeErr = false;
        }
      } else if (e.dataTransfer.items.length > 0) {
        this.showPhotoUploadBox = false;
        this.showMaxLengthErr = true;
        e.target.value = "";
      }
      if (count_file > this.maxLength) {
        this.showMaxLengthErr = true;
        e.target.value = "";
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      this.$store.dispatch("setUploadedImages", this.uploadImage);
    },
    doNothing(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    async deleteImg(filename, key) {
      if (!this.showPhotoUploadBox) this.showPhotoUploadBox = true;
      if (this.showMaxLengthErr) this.showMaxLengthErr = false;
      this.onUpload = true;
      try {
        await PhotoUploadService.deteleImg(filename);
      } catch (err) {
        if (`${err}`.includes("Network Error")) {
          this.showNetworkIssueErr = true;
        } else {
          console.error(err);
        }
      }
      this.uploadImage = await this.uploadImage.filter(
        (item) => item.filename !== filename
      );
      this.onUpload = false;
      this.imageDeleting = true;
      setTimeout(() => (this.imageDeleting = false), 300);
      this.checkingImg[key] = false;
      if(this.uploadImage.length === 1 || key == "exteriorFront") {
        this.curFrame = this.photoOrdersFiltered[0];
      }
      this.$store.dispatch("setUploadedImages", this.uploadImage);
    },
    async deleteAllImages() {
      Promise.all(
        this.uploadImage.map((img) =>
          PhotoUploadService.deteleImg(img.filename)
        )
      );
      for (const [key] of Object.entries(this.checkingImg)) {
        this.checkingImg[key] = false;
      }
      this.uploadImage = [];
      this.curFrame = this.photoOrdersFiltered[0];
      this.$store.dispatch("clearImages");
    },
    openHelpGuide() {
      this.showHelpGuide = !this.showHelpGuide;
    },
    toggleBodyTypePanel() {
      this.showHelpGuide = false;
      this.showFrameSelection = false;
      this.showBodyTypePanel = !this.showBodyTypePanel;
    },
    updateBodyType(bodyType) {
      this.curBodyType = bodyType;
      this.showBodyTypePanel = false;
      this.$store.dispatch(SET_INFO, {
        ...this.info,
        bodyType: bodyType
      });
      // Update current Frame
      this.nextFrame();
    },
    closeDropdown() {
      this.showBodyTypePanel = false;
      this.showFrameSelection = false;
    },
    openFrameSelection () {
      this.showBodyTypePanel = false;
      this.showFrameSelection = !this.showFrameSelection;
    },
    enableExitConfirmDialog() {
      this.isClosing = true;
    },
    closeCamera() {
      this.exitCamera();
      this.showCameraOption = false;
      this.isClosing = false;
      this.toggleChatSection();
    },
    saveAndExit() {
      this.useThisPhoto();
      this.closeCamera();
    },
    discardAndExit() {
      this.deleteAllImages();
      this.closeCamera();
    },
    setCurrentPhoto(index) {
      this.curFrame = this.photoOrdersFiltered[index];
      this.showTitle = false;
      if(this.curBodyType == "SUV" && this.curFrame.key == "interiorBoot"){
       this.curFrame.frame  = "Interior/Boot - SUV NEW.svg";
      }
      this.showTitle = false;
      this.$nextTick(() => {
        this.showTitle = true;
      });
      this.showFrameSelection = false;
    },
    nextFrame() {
      this.showTitle = false;
      if(this.uploadImage.length >= this.photoOrdersFiltered.length) {
        this.curFrame = this.photoOrdersFiltered[this.photoOrdersFiltered.length - 1];
      } else {
        for (let photoFrame of this.photoOrdersFiltered) {
          if (photoFrame.haveImg === false) {
            this.curFrame = photoFrame;
            break;
          }
        }
      }
      this.showTitle = false;
      this.$nextTick(() => {
        this.showTitle = true;
      });
    },
    getCurrentCapturePhotoFrameTitle() {
      const title = this.curFrame.title;
      if (title) {
        return title.toUpperCase();
      }
    },
    getChatSection() {
      return document.getElementsByTagName('mmgp-sms-chat-widget')[0];
    },
    toggleChatSection() {
      const chatSection = this.getChatSection();
      if (chatSection) {
        if (chatSection.style.display === 'none') {
          chatSection.style.display = "block";
        } else {
          chatSection.style.display = "none";
        }
      }
    },
    closeBlockScreen() {
      const blockScreen = document.querySelector('.display-when-portrait');
      if (blockScreen) {
        blockScreen.style.display = "none";
      }
    },
    active(num) {
      this.questions[num].isActive = !this.questions[num].isActive;
    },
    activeReportIssue() {
      this.isActiveReportIssue = !this.isActiveReportIssue;
    },
    imageValidate(file, callback) {
      this.errImgSize = [];
      this.errImgType = [];
      this.showImgSizeErr = false;
      this.showImgTypeErr = false;
      if (!EXTENSIONS.includes(file.name.split('.').pop().toLowerCase())) {
        this.errImgType = [...this.errImgType, file.name];
        this.showImgTypeErr = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        callback(false);
      } else if (file.size > this.max) {
        this.errImgSize = [...this.errImgSize, file.name];
        this.showImgSizeErr = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        callback(false);
      } else callback(true);
    },
    mandatoryCheck() {
      for (const item of mandatoryList) {
        if (!this.uploadImage.find((image) => image.key === item.key)) {
          this.showMandatoryErr = true;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return false;
        }
      }
      return true;
    },
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    handleBeforeUnload(event) {
      if (!this.isIdle) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    async saveOrUpdateTempData() {
      this.$store.dispatch("setUploadedImages", this.uploadImage);
      this.$store.dispatch(UPDATE_DATA, this.authenticateData);
    },
    addToDeleted(imageUrl) {
      if (!this.authenticateData.deletedImages.includes(imageUrl)) {
        this.$store.dispatch(SET_DELETED_IMAGES, [
          ...this.authenticateData.deletedImages,
          imageUrl,
        ]);
      }
      this.onUpload = true;
      this.uploadImage = this.uploadImage.map((image) =>
        image.url === imageUrl
          ? {
              ...image,
              deleted: true,
            }
        : image
      );
      this.onUpload = false;
      this.$store.dispatch("setUploadedImages", [...this.uploadImage]);
    },
    async checkPredominantColors(imageBuffer, action, predominantColorsDetected) {
      try {
        if (!["image/jpg", "image/jpeg", "image/png", "image/tiff"].includes(imageBuffer.type)) {
          return false;
        }
        let formData = new FormData();
        formData.append("image", imageBuffer);
        const checkResult = await PhotoUploadService.detectPredominantColors(formData);
        const isLoggedError = Object.values(predominantColorsDetected).includes(true);
        if (checkResult && !isLoggedError) {
          predominantColorsDetected[action] = true;
          const imageDetails = {
            type: imageBuffer.type,
            size: imageBuffer.size,
          }
          this.logPredominantColorsError(action, imageDetails);
        }
        return checkResult;
      } catch (err) {
        logError(`Check predominant colors error: ${err}`);
        return false;
      }
    },
    logPredominantColorsError(action, imageDetails = {}) {
      const hint = {
        imageDetails,
        appraisalId: this.authenticateData.appraisalId,
        leadId: this.authenticateData.leadId,
      };
      logError(`Predominant colour is detected from "${action}". ${JSON.stringify(hint)}`);
    },
    runCheckersSequentially(checkers) {
      let currentCheckerIndex = 0;
      const runNextChecker = () => {
        if (currentCheckerIndex < checkers.length) {
          // Run the current checker and move to the next one only if it returns false
          checkers[currentCheckerIndex]().then(result => {
            if (result === true) {
              return; // Stop execution of remaining checkers
            }
            currentCheckerIndex++;
            runNextChecker(); // Run the next checker
          });
        }
      };
      runNextChecker(); // Start the sequence
    },
  },
  watch: {
    "stateImgs": function() {
      this.isCurrPageChanges = true;
      this.$store.dispatch('setOnEditPage', true);
    },
  },
  mounted() {
    this.showPhotoUploadBox = this.stateImgs.length < 30;
    this.uploadImage = this.stateImgs;
    this.curBodyType = this.bodyTypes.some(bodyType => bodyType.toLowerCase() === this.info.bodyType.toLowerCase()) ? this.info.bodyType : 'Sedan';
    this.curFrame = this.photoOrdersFiltered[0];

    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
    if (this.isMobile) {
      const userAgent = navigator.userAgent || window.opera; // Check user use in-app facebook or instagram browser android so can only upload
      const isAndroidSpecialBrowser = userAgent.includes('Android') && socialNetworkBrowser.some(browser => userAgent.includes(browser));
      const isiOSChromeBrowser = (userAgent.includes('iPhone') || userAgent.includes('iPad')) && userAgent.includes(browserList);
      
      if (isAndroidSpecialBrowser) {
        this.isSpecialDeviceBrowser = true;
        this.isDialogPhotoUploadIssueOpen = true;
      } else if (isiOSChromeBrowser) {
        this.isSpecialDeviceBrowser = true;
      }
    }

     // Replace brand name in questions and answer
    this.questions = this.questions.map(question => {
      return { ...question, answer: question.answer.replace('{brandName}', this.brandName)}
    });
    // Check is close tab
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    this.$store.dispatch('setOnEditPage', false);

    this.$root.$on('initImgUploadCamera', this.initImgUploadCamera);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    this.$root.$off('initImgUploadCamera', this.initImgUploadCamera);
  },
};
</script>

<style lang="scss">
// .welcome-message {
//   font-size: 20px;
//   line-height: 28px;
//   color: #1F8252;
//   margin-bottom: 0 !important;
//   p {
//     margin-bottom: 0.5rem !important;
//   }
// }
.photo-description {
  font-size: 16px !important;
  text-align: left;
  font-family: "Avenir Next LT W05 Regular" !important;
  .photo-description-link {
    color: var(--photo-tips-text-link);
  }
}
.photo-description-list {
  padding-left: 38px;
}
///
.camera-modal {
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: rgb(51, 51, 51);
  }
  .modal-content {
    width: 100%;
    height: 100%;
    background-color: initial;
  }
  .modal-body {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .camera-screen {
    background: white;
    position: relative;
    width: 100%;
    height: 100%;
    .function-section {
      height: 90px;
      width: 100%;
      background: rgba(37, 43, 47, 0.5);
      backdrop-filter: blur(22px);
      z-index: 1700;
      position: fixed;
      bottom: 0;
      left: 0;

      .shoot-btn {
        width: 100px;
        height: 100px;
        position: fixed;
        bottom: 45px;
        left: 50%;
        background: rgba(37, 43, 47, 0.5);
        -webkit-backdrop-filter: blur(22px);
        backdrop-filter: blur(22px);
        border-radius: 50%;
        transform: rotate(90deg) translate(5px, 40px);

        ::before {
          content: '';
          width: 2px;
          height: 50px;
          background: #ffffff;
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -2px;
        }
        ::after {
          content: '';
          border: 1px solid #ffffff;
          width: 1px;
          height: 1000px;
          background: #ffffff;
        }
      }

      .button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        // padding: 0 20px;

        .upper-btn {
          .btn {
            margin: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .close-btn {
            border-radius: 50% !important;
            border: 2px solid #ffffff;
            background: rgba(255, 255, 255, 0.25);
            width: 50px;
            height: 50px;
            color: #ffffff;
            transform: rotate(90deg);
          }
        }

        .under-btn {
          display: flex;
          flex-direction: row-reverse;
          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .btn:first-child {
            margin: 10px 0px;
          }
          .btn:last-child {
            margin: 10px;
          }
          .body-type-btn, .help-btn {
            border-radius: 50% !important;
            border: 2px solid #ffffff;
            background: rgba(255, 255, 255, 0.25);
            width: 50px;
            height: 50px;
            transform: rotate(90deg);
          }
          .help-btn {
            color: #ffffff;
            font-size: 32px;
            &.active {
              background: #2BB673;
            }
          }
        }
      }
    }
    #camera-output {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1058;
      object-fit: cover;
    }
    #camera-outlay {
      user-select: none;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 90px);
      z-index: 1057;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: calc(100vh - 120px);
        height: 45%;
        transform: rotate(90deg);
        padding-right: 90px;
        padding-left: 50px;
      }
    }
    .camera-view {
      pointer-events: none;
      user-select: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1056;
      object-fit: cover;
    }
    .body-type-dropdown {
      z-index: 1700;
      position: fixed;
      transform: rotate(90deg) translateX(calc(-50% - 127px));
      top: 50%;
      left: 50px;
      color: #FFFFFF;
      ul {
        background: rgba(37, 43, 47, 0.5);
        border: 2px solid #FFFFFF;
        -webkit-backdrop-filter: blur(19px);
        backdrop-filter: blur(19px);
        border-radius: 22px;
        list-style-type: none;
        padding: 0 !important;
        max-height: 300px;
        overflow-y: auto;
        li {
          background: transparent;
          border-bottom: 1px solid #ffffff;
          height: 40px;
          padding: 10px 20px;
          max-width: 190px;
          &:last-child {
            padding-bottom: 50px;
          }
          .option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .body-type-status {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .photo-frame-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 1060;
      .photo-frame-dropdown {
        transform: translateY(calc(-50% - 60px));
        position: fixed;
        top: 50%;
        right: -3px;
        color: #FFFFFF;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .photo-image-list-wrapper {
          background: rgba(37, 43, 47, 0.5);
          border: 2px solid #FFFFFF;
          -webkit-backdrop-filter: blur(19px);
          backdrop-filter: blur(19px);
          width: 330px;
          height: 330px;
          border-bottom: none;
          transform: rotate(90deg);
          ul {
            list-style-type: none;
            padding: 0 !important;
            margin: 0 !important;
            height: 100%;
            // width: 100%;
            overflow-y: auto;
            li {
              background: transparent;
              border-bottom: 1px solid #ffffff;
              height: 40px;
              padding: 10px;
              width: 326px !important;
              line-height: 16px;
              .option {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .frame-type, .frame-name {
                  font-weight: 700;
                  font-size: 17px;
                  color: #B8B8B8;
                }
                .frame-type {
                  width: 25%;
                }
                .frame-name {
                  color: #ffffff;
                  width: 55%;
                }
                .frame-status {
                  width: 10%;
                }
                @media screen and (max-width: 667px) {
                  .frame-type, .frame-name {
                    font-size: 15px !important;
                  }
                }
              }
            }
          }
        }
        .photo-frame-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          background: rgba(37, 43, 47, 0.5);
          border: 2px solid #FFFFFF;
          -webkit-backdrop-filter: blur(19px);
          backdrop-filter: blur(19px);
          border-radius: 22px 0 0 22px;
          height: 330px;
          text-orientation: mixed;
          -ms-writing-mode: tb-rl;
          writing-mode: tb-rl;
          .screenshot-title, .select-angle-btn {
            font-weight: 700;
            text-align: center;
            color: #FFFFFF;
          }
          .screenshot-title {
            height: 100%;
            padding: 10px 0px;
          }
          .select-angle-btn {
            padding: 20px 0px;
            font-size: 14px;
            line-height: 22px;
            border-top: 2px solid #ffffff;
          }
        }
      }
    }
  }
  .confirm-option-wrapper {
    position: fixed;
    left: -15px;
    z-index: 1200;
    top: 50% !important;
    flex-direction: column;
    transform: translateY(calc(-50% - 55px));
    .confirm-option {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      b {
        color: #fff;
        font-size: 17px;
      }
    }
    .use-this-photo, .take-again {
      background: #252B2F;
      border: 2px solid #FFFFFF;
      border-radius: 20px;
      color: #ffffff;
      text-orientation: mixed;
      writing-mode: vertical-lr;
      height: 170px;
      width: 65px;
      .confirm-text {
        white-space: nowrap;
        margin: 0 0 10px 0;
      }
      .confirm-icon {
        transform: rotate(90deg);
        width: 20px;
        height: 20px;
      }
    }
    .take-again {
      height: 190px;
    }
    .overlay {
      top: -30px;
      .use-this-photo {
        background-color: #05b36d;
      }
    }
  }
}

// .modal-init-upload {
//   .modal-header {
//     padding: 10px !important;
//     border: 0 !important;
//   }
//   .modal-dialog .modal-content {
//     background-color: rgb(55, 179, 114, 0.9) !important;
//     border-radius: 25px !important;
//   }
//   .modal-body {
//     margin: 30px auto;
//     padding: 1.4rem !important;
//     width: 100%;
//     .upload-option-wrapper {
//       display: grid;
//       grid-template-columns: 60% 40%;
//       column-gap: 20px;
//       width: 100%;
//       justify-content: center;
//       .upload-option {
//         padding-top: 1em !important;
//         padding-bottom: 1em !important;
//         background-color: #fff;
//         border-radius: 10px;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         justify-items: center;
//         align-items: center;
//       }
//       .upload-camera {
//         .option-icon {
//           color: #05b36d;
//           font-size: 60px;
//         }
//         b {
//           color: #05b36d;
//         }
//       }
//       .upload-file {
//         .option-icon {
//           color: rgb(112, 112, 112);
//           font-size: 60px;
//         }
//         b {
//           color: rgb(112, 112, 112);
//         }
//       }
//     }
//   }
// }

.photo-upload-content {
  text-align: center;
  padding: 0 10px;
  .photo-div {
    position: relative;
    display: flex;
    width: 100%;
  }

  .photo-div ol {
    width: 100%;
    position: relative;
  }
  .photo-div:not(.photo-div-optional) ol {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  ol,
  ul {
    list-style: none;
  }
  
  .photo-div .photo-list .photo-list-optional,
  .photo-div .photo-list .photo-list-item {
    width: 100%;
    height: calc(30vw - 0.5rem);
    min-width: 150px;
    overflow: hidden;
    cursor: move;
    border-radius: 0.5rem;
    img:not(.edit-photo, .upload-fail-icon) {
      height: calc(30vw - 0.5rem);
      object-fit: cover;
    }
    position: relative;
    img.upload-item-list {
      width: calc(9vw - 0.5rem);
      height: calc(9vw - 0.5rem) !important;
      min-width: 35px;
      min-height: 35px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .img-onUpload {
      opacity: 50%;
    }
  }
  .photo-div .photo-list .photo-list-optional {
    width: unset !important;
  }
  .photo-div .photo-list .photo-list-item:not(:first-child) {
    .upload-fail-text {
      width: 150px;
      justify-content: center;
    }
  }
  .uploaded-fail {
    cursor: default !important;
  }
  .edit-photo {
    z-index: 100;
    position: relative;
    height: 25px;
    width: 25px;
    bottom: 29px;
    cursor: pointer;
  }
  .edit-photo.left {
    float: left;
    left: 5px !important;
  }
  .edit-photo.right {
    float: right;
    right: 5px !important;
  }
  .edit-photo.top {
    bottom: calc(30vw - 0.5rem - 5px);
  }
  .photo-upload {
    background-color: white;

    .upload-section {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content 1fr;

      .upload-switcher {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 16px 0;

        .option {
          width: 100%;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(65, 73, 66, 0.5);
          border-bottom: 1px solid rgba(65, 73, 66, 0.5);
          position: relative;
          cursor: pointer;

          .upload-switcher-tooltip {
            position: absolute;
            top: 40px;
            width: 230px;
            background-color: #2e312e;
            color: #f0f1ec;
            border-radius: 4px;
            padding: 4px 8px 4px 8px;
            font-family: "Avenir Next LT W05 Regular";
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            z-index: 999;
            display: none;
          }

          &.disabled {
            cursor: default;
          }

          &.show-tooltip:not(.active):hover .upload-switcher-tooltip {
            display: block;
          }

          &.active {
            color: black;
            border-bottom: 2px solid #006D41;
          }
        }
      }
    }

    .container-question {
      max-width: 345px;
      padding: 0 10px;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      
      .item {
        margin-bottom: 0.5rem;

        u {
          color: var(--primary-color);
        }

        span {
          .not-collapsed {
            transform: rotate(180deg);
            transition: ease-in-out 0.3s;
          }
          .collapsed {
            transition: ease-in-out 0.3s;
          }
        }

        img {
          width: 14px;
          height: 8px;
          cursor: pointer;
          margin-left: 5px;
          transition: all .5s;
        }
      }

      .quick-answer {
        margin-top: 8px;
        margin-bottom: 8px;
        color: #6F6F6F;
        u {
          font-family: Avenir Next LT W05 Regular !important;
          font-weight: 700 !important;
          color: #0f0f0f;
        }
      }
    }
  }
  .photo-upload-header {
    background-color: #e4f6ee;
    text-align: center;
    font-weight: 900;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .upload-btn {
    width: 100%;
    background-color: #e4f6ee !important;
    color: #05b36d !important;
    border-color: #05b36d !important;
  }
  .upload-item {
    background-color: #eee;
    border: 1px solid var(--cover-photo-border) !important;
    cursor: pointer !important;
    overflow: hidden;
    display: inline-block;
  }
  .img-upload-fail {
    background-color: #eee;
  }
  .upload-fail-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .upload-fail-icon {
      width: 14px !important;
      height: 14px !important;
    }
    p {
      color: #D20319 !important;
      font-size: 11px !important;
      margin-left: 5px;
      margin-bottom: 0;
    }
  }
  .deleted-mask::before {
    content: "DELETED";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
  }
  .deleted-mask::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(176, 0, 32, 0.6);
  }
  .img-upload-progress {
    z-index: 999;
    position: absolute;
    bottom: 10px;
    width: 90%;
    left: 5%;
    height: 2%;
  }

  .alert {
    .alert-container {
      img {
        width: 30px;
      }
    }
    // &.save-banner {
    //   background-color: #82d6a3;
    //   border: 1px solid #1f8252;
    //   color: #333333;
    //   width: 98%;
    // }
  }
  .alert-dismissible {
    font-weight: bold !important;
    font-family: Avenir Next LT W05 Regular !important;
    border-radius: 0.5rem !important;
    .close {
      top: 20% !important;
      transform: translateY(-50%) scale(1.25);
    }
    button {
      font-weight: bold !important;
      font-family: Avenir Next LT W05 Regular !important;
      border-radius: 0.5rem !important;
    }
  }
  .alert-danger {
    color: #FF6868 !important;
    background-color: #FFE4E4 !important;
    border-color: #FF6868 !important;
  }
  .col {
    padding: 0;
  }
  .min-w-222 {
    min-width: 222px;
  }

  .display-when-portrait {
    display: block;
  }

  .btn-group-wrapper {
    margin-top: 1.5rem !important;

    .action-btn {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      border-radius: 8px;
    }

    .save-draft-btn {
      background: #CACACA;
      color: #FFFFFF;
      border: none !important;
      @media screen and(min-width: 768px) {
        max-width: 145px;
      }
    }
  }
}

.card {
  border: none !important;
}

.content-title {
  color: var(--primary-color);
}

@media screen and (orientation: landscape) {
  .display-when-portrait {
    display: none !important;
  }
  .camera-modal {
    .camera-screen {
      #camera-outlay {
        top: 0;
        left: 0;
        height: 100%;
        width: calc(100% - 90px);
        transform: none;
        img {
          width: calc(100vw - 120px);
          height: 90%;
          transform: none;
          padding-right: 60px;
          padding-top: 40px;
        }
      }
      .function-section {
        width: 90px;
        height: 100%;
        background: rgba(37, 43, 47, 0.5);
        backdrop-filter: blur(22px);
        right: 0;
        left: unset;
        .shoot-btn {
          bottom: 50%;
          left: unset !important;
          transform: translate(-50px, 50%);
        }
        .button-wrapper {
          flex-direction: column-reverse;
          .under-btn {
            display: flex;
            flex-direction: column;
            .btn:first-child {
              margin: 0px 10px;
            }
            .btn:last-child {
              margin: 10px;
            }
            .body-type-btn, .help-btn {
              transform: none;
            }
          }
          .upper-btn {
              .close-btn {
                transform: none;
              }
          }
        }
      }
      .body-type-dropdown {
        transform: rotate(0deg) translateX(calc(-50% - 43px));
        top: unset;
        left: 50%;
        bottom: -40px;
        ul {
          max-height: 300px;
          overflow-y: auto;
          li {
            height: 40px;
            padding: 10px 20px;
            &:last-child {
              padding-bottom: 50px;
            }
          }
        }
      }
      .photo-frame-wrapper {
        .photo-frame-dropdown {
          top: -3px;
          transform: translateX(calc(-50% - 60px));
          right: unset;
          left: 50%;
          flex-direction: column;
          .photo-image-list-wrapper {
            border-bottom: none;
            border-left: 2px solid #ffffff;
            transform: none;
            ul {
              li {
                .option {
                  .frame-name {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          @media screen and (max-height: 374px) {
            .photo-image-list-wrapper {
              height: 230px;
            }
          }
          .photo-frame-header {
            height: 50px;
            text-orientation: unset;
            -ms-writing-mode: tb-rl;
            writing-mode: unset;
            width: 330px;
            border-radius: 0 0 22px 22px;
            .screenshot-title {
              width: 100%;
              padding: 0px 10px;
              line-height: 40px;
            }
            .select-angle-btn {
              padding: 0px 20px;
              border-left: 2px solid #ffffff;
              border-top: none;
            }
          }
        }
      }
    }
    .confirm-option-wrapper {
      left: 50%;
      bottom: -20px !important;
      transform: translateX(calc(-50% - 40px));
      top: unset !important;
      flex-direction: row;
      height: 65px;
      width: 400px;
      .confirm-option {
        padding-top: 15px !important;
        padding-bottom: 25px !important;
        b {
          color: #fff;
          font-size: 17px;
        }
      }
      .use-this-photo, .take-again {
        text-orientation: unset;
        writing-mode: unset;
        width: 170px;
        height: 65px;
        .confirm-text {
          margin: 0 10px 0 0;
        }
        .confirm-icon {
          transform: none;
        }
      }
      .take-again {
        padding-right: 10px;
        width: 190px;
      }
      .overlay {
        top: unset;
        left: -30px;
      }
    }
  }
}

.nudge {
  .heading{
    margin-bottom: 0;
    font-weight: bold !important;
  }
  .alert-heading {
    font-weight: normal !important;
    color: var(--quick-tip-text);
    background-color: var(--quick-tip-background);
    border: 1px solid var(--quick-tip-border);
    box-sizing: border-box;
    border-radius: 10px;
    width: 98%;
    margin: 10px auto;
    padding: 10px 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin-bottom: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;

    img {
      margin-right: 5px;
    }
  }
  }
}

@media screen and(min-width: 900px) {
  .photo-upload-content {
    padding: 0 !important;

    .photo-upload {
      .upload-section {
        .upload-box-container {
          padding-left: 30px;
          padding-right: 30px;
        }
        .upload-switcher {
          margin: 16px 30px;
        }
        .upload-tips {
          margin-left: 30px;
          margin-right: 30px;
        }
      }

      .divider-section {
        padding: 0 28px;
      }

      .forward-btn {
        width: auto;
        position: absolute;
        right: 190px;
        bottom: 55px;
        background-color: #ffffff !important;
      }
      .alert-dismissible {
        .close {
          top: 5% !important;
          transform: scale(1.5) !important;
          z-index: 999999;
        }
      }
      .photo-div .photo-list .photo-list-optional {
        width: 169px;
        height: 109px;
        min-width: unset;
        z-index: 100000;
        position: relative;

        img:not(.edit-photo, .upload-fail-icon) {
          height: 109px;
        }

        .edit-photo {
          width: 18px;
          height: 18px;
          bottom: 23px;
        }

        .edit-photo.top {
          bottom: calc(109px - 5px);
        }
      }
      .photo-div .photo-list {
        grid-template-columns: repeat(3, 169px);
        gap: 15px;

        .photo-list-item {
          height: 109px !important;
          min-width: unset;
          z-index: 100000;
          position: relative;
    
          img:not(.edit-photo, .upload-fail-icon) {
            height: 109px !important;
          }
    
          img.upload-item-list {
            width: 35px !important;
            height: 35px !important;
          }
    
          .edit-photo {
            width: 18px;
            height: 18px;
            bottom: 23px;
          }
    
          .edit-photo.top {
            bottom: calc(109px - 5px) !important;
          }
        }
      }
      .photo-div {
        padding: 0.25rem;
        margin-top: 20px;
        .wide-list {
          width: auto !important;
          padding: 0 1rem !important;
          margin-left: 0.4rem;
          margin-bottom: 0 !important;
        }
      }
      .photo-div-optional {
        width: 124%;
      }
      .divider-wrapper {
        display: flex !important;
      }

      .container-question {
        padding: 0 !important;
        margin-left: 30px !important;

        span {
          &:hover {
            transform: translateY(-1.5px);
            transition: ease-in-out 0.2s;
          }
        }
      }
    }
  }

  .nudge {
    .alert-dismissible {
      .close {
        top: 20% !important;
      }
    }
    .heading {
      font-size: 20px;
    }
    .alert-heading {
      font-weight: bold !important;
      font-size: 16px;
    }
  }
  .photo-upload {
    .listed-message-banner {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

@media screen and(max-width: 991px) {
  .container-question {
     margin-left: 0 !important;
     margin-right: 0 !important;
  }
}

@media screen and(max-width: 768px) {
  .btn-group-wrapper {
    .back-btn {
      transform: translateY(145px);
    }

    .submit-btn {
      margin-left: unset !important;
    }

    .save-draft-btn {
      margin-right: unset !important;
    }
  }

  .mandatory-alert {
    min-width: 351px;
    margin-left: unset !important;
    margin-right: unset !important;
    @media screen and(max-width: 483px) and(min-width: 386px) {
      padding: 0.75rem 2.5rem;
    }
  }
}

@media screen and(max-width: 386px) {

  .photo-description {
    padding: 10px !important;
  }

  .image-container {
    img {
      max-width: 348px;
    }
  }

  .mandatory-alert {
    min-width: 325px;
    @media screen and(max-width: 356px) {
      min-width: 256px;
    }
  }
}

@media screen and(min-width: 768px) {
  .photo-upload-content {
    .btn-group-wrapper.custom-padding-1 {
      position: sticky !important;
      padding-bottom: 0 !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .btn-group-wrapper.custom-padding-2 {
      position: fixed !important;
      padding-left: 98px !important;
      padding-right: 98px !important;
    }
  }
  .photo-description-list {
    padding-left: 28px !important;
  }
}

// Tablet
@media screen and (min-width: 468px ) {
  .photo-upload-content .photo-div .photo-list {
    .photo-list-optional {
      background-size: 170px;
    }
  }
}
@media screen and (min-width: 600px ) and (max-width: 767px) {
  .photo-upload-content .photo-div .photo-list {
    .no-item-optional {
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        background-size: 110px !important;
      }
    }
    .first-item-optional {
      &:first-child {
        background-size: 110px !important;
      }
    }
  }
}
@media screen and (min-width: 768px ) and (orientation: portrait) {
  .photo-upload-content .photo-div .photo-list {
    .photo-list-optional {
      background-size: 250px !important;
    }
    .no-item-optional {
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        background-size: 150px !important;
      }
    }
    .first-item-optional {
      &:first-child {
        background-size: 150px !important;
      }
    }
  }
}
@media screen and (min-width: 768px ) and (max-device-width: 1200px) and (orientation: landscape) {
  .photo-upload .photo-div .photo-list .photo-list-optional {
    width: 188px;
  }
}

@media screen and (min-width: 912px ) and (min-height: 1368px) and (orientation: portrait) {
  .photo-upload-content .photo-div .photo-list .photo-list-optional {
    background-size: 130px !important;
  }
}

@media screen and (min-width: 992px ) and (max-width: 1200px) {
  .photo-upload .photo-div .photo-list .photo-list-optional {
    width: 188px !important;
  }
}

@media screen and (min-width: 900px ) and (max-width: 990px) {
  .photo-upload .photo-div .photo-list {
    .photo-list-optional {
      width: 174px !important;
      background-size: 130px !important;
    }
    .no-item-optional {
      &:nth-child(1), &:nth-child(2) {
        background-size: 65px !important;
      }
      &:nth-child(3) {
        background-size: 75px !important;
      }
    }
    .first-item-optional {
      &:first-child {
        background-size: 65px !important;
      }
    }
  }
}

@media screen and (min-width: 800px ) and (max-width: 900px) and (orientation: landscape) {
  .photo-upload .photo-div-optional {
    padding-left: 0 !important;
  }
  .photo-upload .photo-div .photo-list {
    .photo-list-optional {
      width: 319px !important;
      background-size: 200px !important;
    }
    .no-item-optional {
      &:nth-child(1), &:nth-child(2) {
        background-size: 140px !important;
      }
      &:nth-child(3) {
        background-size: 130px !important;
      }
    }
    .first-item-optional {
      &:first-child {
        background-size: 140px !important;
      }
    }
  }
  .mandatory-alert {
    margin-left: unset !important;
    margin-right: unset !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .btn-container {
    margin-right: 0 !important;
  }
}

@media screen and(min-width: 991px) {
  .photo-upload-content .photo-upload {
    .upload-section {
      grid-template-columns: auto 50%;
  
      .content-title, .upload-tips {
        grid-column-start: 1;
        grid-column-end: 2;
      }
  
      .content-title.centered-title {
        transform: translateY(85px);
      }

      .content-title.guided-upload-no-err {
        grid-row-start: 1;
        grid-row-end: 3;
        line-height: 48px;
      }

      .upload-switcher {
        grid-column-start: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        margin-top: 0;

        &.guided-upload-mode {
          grid-row-start: 1;
          grid-row-end: 3;
          align-items: flex-end;
          margin-left: 0;
          margin-bottom: 0;
        }
      }
  
      .upload-box-container {
        grid-column-start: 2;
        grid-row-start: 2;
        grid-row-end: 3;
      }
  
      .upload-tips {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
      }
    }

    .photo-div .photo-list {
      grid-template-columns: repeat(4, 169px);
      column-gap: 34px;
      row-gap: 20px;
    }
  }
}

@media screen and(min-width: 1200px) {
  .photo-upload-content .photo-upload .photo-div .photo-list {
    grid-template-columns: repeat(5, 169px);
    column-gap: 28px;
  }
}
</style>